/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-else-return */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/order */
/* eslint-disable prefer-const */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React, { useEffect, useMemo, useState } from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  AppBar,
  Toolbar,
  Typography,
  TableContainer,
  // Paper,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ConfirmOrder from "./confirm_order";
import ExportData from "./export_order";
import { AgGridReact } from "ag-grid-react";
import close from "assets/images/close.png";
import { useNavigate } from "react-router-dom";
import "../../styles/style.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, Paper } from "@material-ui/core";
import useCustomers from "hooks/customers/useCustomers";
import useContainers from "hooks/containers/useContainers";
import { CFormLabel, CFormSelect } from "@coreui/react";
import image from "../../assets/images/image.png";
// import { useTranslation } from "react-i18next";
// import DeleteLab from "./delete_lab";
const ExcelJS = require("exceljs");
const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
      let reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
  return promise;
};
function AllOrders() {
  const [loading, setloading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedContainer, setSelectedContainer] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { customers } = useCustomers();
  const { containers } = useContainers();
  const [selectedContainerID, setSelectedContainerID] = useState("");
  const [checked, setChecked] = useState([]);

  // const memoizedFilteredOrders = useMemo(() => {
  //   return orders.filter((_, index) => checked[index]);
  // }, [orders, checked]);

  // useEffect(() => {
  //   setSelectedOrders(memoizedFilteredOrders);
  // }, [memoizedFilteredOrders]);

  // const handleFieldChange = (index, field, value) => {
  //   const updatedOrders = [...selectedOrders];
  //   updatedOrders[index] = { ...updatedOrders[index], [field]: value };
  //   setSelectedOrders(updatedOrders);
  //   setOrders(updatedOrders)
  //   console.log(selectedOrders)
  // };

  const [orderid, setOrderID] = useState([""]);
  const [IDs, setID] = useState([""]);
  const [ordercode, setOrderCode] = useState([""]);
  const [customer_id, setCustomerID] = useState([""]);
  const [itemarray, setItemArray] = useState([""]);
  const [statusProducts, setStatusProduct] = useState([""]);
  const [itemnumber, setItemNumber] = useState([""]);
  const [photo, setPhoto] = useState([""]);
  const [description1, setDescription1] = useState([""]);
  const [description2, setDescription2] = useState([""]);
  const [category, setCategory] = useState([""]);
  const [ctn, setCTN] = useState([""]);
  const [qtytoctn, setQtyToCtn] = useState([""]);
  const [utoprice, setUtoPrice] = useState([""]);
  const [cbm, setCBM] = useState([""]);
  const [t_cbm, setTCBM] = useState([""]);
  const [nw, setNW] = useState([""]);
  const [status, setStatus] = useState([""]);
  const [order_status, setOrderStatus] = useState([""]);
  const [notes, setNotes] = useState([""]);
  const [ctnorders1, setCTNOrder] = useState([]);
  const [gw, setGw] = useState([]);
  const [amoutns, setAmounts] = useState([]);

  async function getOrderDetails() {
    const headers = { ContentType: `application/json` };
    const response = await fetch(`https://trust-cargo.co/anas_backend/api/order_details`, {
      headers,
    });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["order_details"]);
    setChecked(Array(data["order_details"].length).fill(true));
    setloading(false);
  }

  const handleOrderCodeChange = (event, index) => {
    setOrderCode((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleProductStautsChange = (event, index) => {
    setStatusProduct((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleItemChange = (event, index) => {
    setItemArray((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleDesc2Change = (event, index) => {
    setDescription2((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleQTYTOCTNChange = (event, index) => {
    setQtyToCtn((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleUTOPriceChange = (event, index) => {
    setUtoPrice((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleNWChange = (event, index) => {
    setNW((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleStatusChange = (event, index) => {
    setStatus((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleNotesChange = (event, index) => {
    setNotes((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCheckBoxChange = (index) => {
    const updatedChecked = [...checked];

    updatedChecked[index] = !updatedChecked[index];
    setChecked(updatedChecked);
    const id2 = [];
    orderFiltered.forEach((e) => {
      id2.push(e.id);
    });
    const gw1 = [];
    orderFiltered.forEach((e) => {
      gw.push(e.g_w);
    });
    const orderid2 = [];
    orderFiltered.forEach((e) => {
      orderid2.push(e.order_id);
    });
    const ordercode2 = [];
    orderFiltered.forEach((e) => {
      ordercode2.push(e.order_code);
    });
    const container_name2 = [];
    orderFiltered.forEach((e) => {
      container_name2.push(e.container_name);
    });
    const customer_id2 = [];
    orderFiltered.forEach((e) => {
      customer_id2.push(e.customer_id);
    });
    const ite2 = [];
    orderFiltered.forEach((e) => {
      ite2.push(e.our_item);
    });
    const status2 = [];
    orderFiltered.forEach((e) => {
      status2.push(e.status);
    });
    const orderstatus = [];
    orderFiltered.forEach((e) => {
      orderstatus.push(e.order_status);
    });

    const photo2 = [];
    orderFiltered.forEach((e) => {
      photo2.push(e.photo);
    });
    const cate1 = [];
    orderFiltered.forEach((e) => {
      cate1.push(e.category);
    });
    orderFiltered.forEach((e) => {
      ctnorders1.push(e.ctn);
    });
    while (cbm.length < orderFiltered.length) {
      orderFiltered.forEach((e) => {
        cbm.push(e.cbm);
      });
    }
    const desc3 = [];
    orderFiltered.forEach((e) => {
      description1.push(e.description1);
    });
    const desc4 = [];
    orderFiltered.forEach((e) => {
      description2.push(e.description2);
    });

    const cbmorders1 = [];
    orderFiltered.forEach((e) => {
      cbmorders1.push(e.cbm);
    });
    const t_cbmorders = [];
    orderFiltered.forEach((e) => {
      t_cbmorders.push(e.t_cbm);
    });

    const nworders1 = [];
    orderFiltered.forEach((e) => {
      nw.push(e.n_w);
    });

    const statusorders1 = [];
    orderFiltered.forEach((e) => {
      status.push(e.status);
    });
    const notesorders1 = [];
    orderFiltered.forEach((e) => {
      notes.push(e.notes);
    });
    orderFiltered.forEach((e) => {
      utoprice.push(e.u_price);
    });
  };

  const filteredOrder = orders.filter((_, index) => checked[index]);
  const orderFiltered = filteredOrder.filter((order) => {
    if (
      (!selectedContainer || order.container_id === selectedContainer) &&
      (!selectedCustomer || order.customer_id === selectedCustomer) &&
      (!selectedStatus || order.order_status === selectedStatus)
    ) {
      return true;
    }
    if (selectedContainer === "all" || selectedCustomer === "all" || selectedStatus === "all") {
      return true;
    }
    return false;
  });
  const id2 = [];
  orderFiltered.forEach((e) => {
    id2.push(e.id);
  });
  orderFiltered.forEach((e) => {
    amoutns.push(e.amount);
  });
  console.log(amoutns);
  const gw1 = [];
  orderFiltered.forEach((e) => {
    gw.push(e.g_w);
  });
  const orderid2 = [];
  orderFiltered.forEach((e) => {
    orderid2.push(e.order_id);
  });
  const ordercode2 = [];
  orderFiltered.forEach((e) => {
    ordercode2.push(e.order_code);
  });
  const container_name2 = [];
  orderFiltered.forEach((e) => {
    container_name2.push(e.container_name);
  });
  const containerIDs = [];
  orderFiltered.forEach((e) => {
    containerIDs.push(e.container_id);
  });
  const customer_id2 = [];
  orderFiltered.forEach((e) => {
    customer_id2.push(e.customer_id);
  });
  const ite2 = [];
  orderFiltered.forEach((e) => {
    ite2.push(e.our_item);
  });
  const status2 = [];
  orderFiltered.forEach((e) => {
    status2.push(e.status);
  });
  const orderstatus = [];
  orderFiltered.forEach((e) => {
    orderstatus.push(e.order_status);
  });
  const photo2 = [];
  orderFiltered.forEach((e) => {
    photo2.push(e.photo);
  });
  const cate1 = [];
  orderFiltered.forEach((e) => {
    cate1.push(e.category);
  });
  orderFiltered.forEach((e) => {
    ctnorders1.push(e.ctn);
  });
  while (cbm.length < orderFiltered.length) {
    orderFiltered.forEach((e) => {
      cbm.push(e.cbm);
    });
  }
  const desc3 = [];
  orderFiltered.forEach((e) => {
    description1.push(e.description1);
  });
  const desc4 = [];
  orderFiltered.forEach((e) => {
    description2.push(e.description2);
  });

  const cbmorders1 = [];
  orderFiltered.forEach((e) => {
    cbmorders1.push(e.cbm);
  });
  const t_cbmorders = [];
  orderFiltered.forEach((e) => {
    t_cbmorders.push(e.t_cbm);
  });

  const nworders1 = [];
  orderFiltered.forEach((e) => {
    nw.push(e.n_w);
  });

  const statusorders1 = [];
  orderFiltered.forEach((e) => {
    status.push(e.status);
  });
  const notesorders1 = [];
  orderFiltered.forEach((e) => {
    notes.push(e.notes);
  });
  orderFiltered.forEach((e) => {
    utoprice.push(e.u_price);
  });
  const handleCTNChange = (event, index) => {
    setCTNOrder((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCBMChange = (event, index) => {
    setCBM((state) =>
      state.map((el, index1) => {
        return index === index1 ? event.target.value : el;
      })
    );
  };
  while (qtytoctn.length < orderFiltered.length) {
    orderFiltered.forEach((e) => {
      qtytoctn.push(e.qty_ctn);
    });
  }
  const visibleOrderIDs = [];
  const visibleOrderCode = [];
  const visibleOrderPhoto = [];
  const visibleIDs = [];
  const visibleContainerIDs = [];
  const visibleCTNOrders = [];
  const visibleAmountOrders = [];
  const visibleUToPriceOrders = [];
  const visibleItem = [];
  const visibleDesc1 = [];
  const visibleDesc2 = [];
  const visibleCBMOrders = [];
  const visibleTCBMOrders = [];
  const visibleNWOrders = [];
  const visibleQTYTOCTNOrders = [];
  const visibleTtoQTYOrders = [];
  const visibleGWOrders = [];
  const visibleStatusOrders = [];
  const visibleNotesOrders = [];
  const visibleCustomers = [];

  for (let i = 0; i < orderFiltered.length; i += 1) {
    if (checked[i]) {
      const initutoPrice = Number(orderFiltered[i].u_price || 0);
      const initQtyCtn = Number(orderFiltered[i].qty_ctn || 0);
      const initUtoPrice = Number(orderFiltered[i].u_price || 0);
      const initCTN = Number(orderFiltered[i].ctn || 0);
      const amount = initutoPrice * (initQtyCtn * initCTN);
      const initTtoQty = initCTN * initQtyCtn;
      visibleOrderIDs.push(orderid2[i]);
      visibleOrderCode.push(ordercode2[i]);
      visibleUToPriceOrders.push(initUtoPrice);
      visibleIDs.push(id2[i]);
      visibleOrderPhoto.push(photo2[i]);
      visibleAmountOrders.push(amount);
      visibleContainerIDs.push(containerIDs[i]);
      visibleItem.push(ite2[i]);
      visibleDesc1.push(desc3[i]);
      visibleDesc2.push(desc4[i]);
      visibleCustomers.push(customer_id[i]);
      visibleCTNOrders.push(ctnorders1[i]);
      visibleTtoQTYOrders.push(initTtoQty);
      visibleCBMOrders.push(cbm[i]);
      visibleTCBMOrders.push(t_cbm[i]);
      visibleNWOrders.push(nw[i]);
      visibleQTYTOCTNOrders.push(qtytoctn[i]);
      visibleGWOrders.push(gw[i]);
      visibleStatusOrders.push(statusorders1[i]);
      visibleNotesOrders.push(notesorders1[i]);
    }
  }

  const total_neCTN = visibleCTNOrders.reduce((e1, e2) => {
    return Number(e1) + Number(e2);
  }, 0);
  const total_t_cbm = t_cbmorders.reduce((e1, e2) => {
    return Number(e1) + Number(e2);
  }, 0);
  console.log(total_t_cbm, t_cbmorders);
  console.log(t_cbm[0]);
  const total_gw =
    visibleGWOrders.length !== 0
      ? visibleGWOrders.reduce((e1, e2) => {
          return Number(e1) + Number(e2);
        })
      : "0";
  const total_amount =
    amoutns.length !== 0
      ? amoutns.reduce((e1, e2) => {
          return Number(e1) + Number(e2);
        })
      : "0";
  const ttoqty = orderFiltered.map((order, index) => {
    return (ctn[index] || 0) * (order.qty_ctn || 0);
  });
  const utopriceorder1 = [];
  orderFiltered.forEach((e) => {
    utopriceorder1.push(e.u_price);
  });
  const amount = orderFiltered.map((order, index) => {
    if (checked[index]) {
      const uPrice = Number.isNaN(Number(utopriceorder1[index]))
        ? 0
        : Number(utopriceorder1[index]);
      const qtyCtn = order.qty_ctn || 0;
      const ctnValue = ctn[index] || 0;
      const result = uPrice * (qtyCtn * ctnValue);
      return result;
    }
  });

  useEffect(() => {
    setloading(true);
    getOrderDetails();
  }, []);
  useEffect(() => {
    const id1 = [];
    orders.forEach((e) => {
      id1.push(e.id);
    });
    const orderid1 = [];
    orders.forEach((e) => {
      orderid1.push(e.order_id);
    });
    const ordercode1 = [];
    orders.forEach((e) => {
      ordercode1.push(e.order_code);
    });
    const container_name1 = [];
    orders.forEach((e) => {
      container_name1.push(e.container_name);
    });
    const customer_id1 = [];
    orders.forEach((e) => {
      customer_id1.push(e.customer_id);
    });
    const ite1 = [];
    orders.forEach((e) => {
      ite1.push(e.our_item);
    });
    const status1 = [];
    orders.forEach((e) => {
      status1.push(e.status);
    });
    const photo1 = [];
    orders.forEach((e) => {
      photo1.push(e.photo);
    });
    const cate = [];
    orders.forEach((e) => {
      cate.push(e.category);
    });
    const ctnorders = [];
    orders.forEach((e) => {
      ctnorders.push(e.ctn);
    });
    const qtytoctnorders = [];
    orders.forEach((e) => {
      qtytoctnorders.push(e.qty_ctn);
    });

    const desc1 = [];
    orders.forEach((e) => {
      desc1.push(e.description1);
    });
    const desc2 = [];
    orders.forEach((e) => {
      desc2.push(e.description2);
    });
    const utopriceorders = [];
    orders.forEach((e) => {
      utopriceorders.push(e.u_price);
    });
    const cbmorders = [];
    orders.forEach((e) => {
      cbmorders.push(e.cbm);
    });

    const nworders = [];
    orders.forEach((e) => {
      nworders.push(e.n_w);
    });
    const orderstatusorders = [];
    orders.forEach((e) => {
      orderstatusorders.push(e.order_status);
    });
    const notesorders = [];
    orders.forEach((e) => {
      notesorders.push(e.notes);
    });
    const gw2 = [];
    orders.forEach((e) => {
      gw2.push(e.g_w);
    });
    setID(id1);
    setOrderID(orderid1);
    setOrderCode(ordercode1);
    setCustomerID(customer_id1);
    setItemArray(ite1);
    setStatusProduct(status1);
    setPhoto(photo1);
    setCategory(cate);
    setCTN(ctnorders);
    // setTCBM(t_cbmorders);
    setQtyToCtn(qtytoctnorders);
    setDescription1(desc1);
    setDescription2(desc2);
    setUtoPrice(utopriceorders);
    setCBM(cbmorders);
    setNW(nworders);
    setStatus(status1);
    setOrderStatus(orderstatusorders);
    setNotes(notesorders);
    setGw(gw2);
  }, [orders]);

  const handleChangeContainer = (event) => {
    setCTNOrder([]);
    setCBM([]);
    setNW([]);
    setDescription1([]);
    setDescription2([]);
    setQtyToCtn([]);
    setStatus([]);
    setUtoPrice([]);
    setGw([]);
    const selectedValue = event.target.value;
    console.log("selectedValue");
    console.log(selectedValue);
    setSelectedContainer(selectedValue);

    const filteredData = filteredOrder.filter((item) => item.container_id === selectedValue);
    console.log("filteredOrder");
    console.log(filteredOrder);
    const initID = [];
    filteredOrder.forEach((e) => {
      initID.push(e.id);
    });
    setID(initID);
    const ctnorders = [];
    filteredData.forEach((e) => {
      ctnorders.push(e.ctn);
    });
    setCTNOrder(ctnorders);
    const cbmorders = [];
    filteredData.forEach((e) => {
      cbmorders.push(e.cbm);
    });
    setCBM(cbmorders);
    const tcbmorders = [];
    filteredData.forEach((e) => {
      t_cbmorders.push(e.t_cbm);
    });
    setTCBM(tcbmorders);
    const nworders = [];
    filteredData.forEach((e) => {
      nworders.push(e.n_w);
    });
    setNW(nworders);
    const desc1orders = [];
    filteredData.forEach((e) => {
      desc1orders.push(e.description1);
    });
    setDescription1(desc1orders);
    const desc2orders = [];
    filteredData.forEach((e) => {
      desc2orders.push(e.description2);
    });
    setDescription2(desc1orders);
    const statusorders = [];
    filteredData.forEach((e) => {
      statusorders.push(e.status);
    });
    setStatus(statusorders);
    const qty_ctnorders = [];
    filteredData.forEach((e) => {
      qty_ctnorders.push(e.qty_ctn);
    });
    setQtyToCtn(qty_ctnorders);
    const upriceorders = [];
    filteredData.forEach((e) => {
      upriceorders.push(e.u_price);
    });
    setUtoPrice(upriceorders);
    const gworder = [];
    filteredData.forEach((e) => {
      gworder.push(e.g_w);
    });
    setGw(gworder);
  };
  const handleChangeCustomer = (event) => {
    setCTNOrder([]);
    setOrderID([]);
    setCBM([]);
    setTCBM([]);
    setNW([]);
    setDescription1([]);
    setDescription2([]);
    setQtyToCtn([]);
    setStatus([]);
    setUtoPrice([]);
    setGw([]);
    const selectedValue = event.target.value;

    setSelectedCustomer(selectedValue);
    const filteredData = filteredOrder.filter((item) => item.customer_id === selectedValue);

    const customerIDs = [];
    filteredData.forEach((e) => {
      customerIDs.push(e.customer_id);
    });
    const ctnorders = [];
    filteredData.forEach((e) => {
      ctnorders.push(e.ctn);
    });
    setCTNOrder(ctnorders);
    const cbmorders = [];
    filteredData.forEach((e) => {
      cbmorders.push(e.cbm);
    });
    setCBM(cbmorders);
    const tcbmorders = [];
    filteredData.forEach((e) => {
      tcbmorders.push(e.t_cbm);
    });
    setTCBM(tcbmorders);

    const nworders = [];
    filteredData.forEach((e) => {
      nworders.push(e.n_w);
    });
    setNW(nworders);
    const desc1orders = [];
    filteredData.forEach((e) => {
      desc1orders.push(e.description1);
    });
    setDescription1(desc1orders);
    const desc2orders = [];
    filteredData.forEach((e) => {
      desc2orders.push(e.description2);
    });
    setDescription2(desc1orders);
    const statusorders = [];
    filteredData.forEach((e) => {
      statusorders.push(e.status);
    });
    setStatus(statusorders);
    const qty_ctnorders = [];
    filteredData.forEach((e) => {
      qty_ctnorders.push(e.qty_ctn);
    });
    setQtyToCtn(qty_ctnorders);
    const upriceorders = [];
    filteredData.forEach((e) => {
      upriceorders.push(e.u_price);
    });
    setUtoPrice(upriceorders);
    const gworder = [];
    filteredData.forEach((e) => {
      gworder.push(e.g_w);
    });
    setGw(gworder);
    setCustomerID(customerIDs);
  };
  const handleChangeStatus = (event) => {
    setCTNOrder([]);
    setCBM([]);
    setTCBM([]);
    setNW([]);
    setPhoto([]);
    setDescription1([]);
    setDescription2([]);
    setQtyToCtn([]);
    setStatus([]);
    setOrderStatus([]);
    setUtoPrice([]);
    setGw([]);
    const selectedValue = event.target.value;
    setSelectedStatus(selectedValue);
    const filteredData = filteredOrder.filter((item) => item.order_status === selectedValue);

    const customerIDs = [];
    filteredData.forEach((e) => {
      customerIDs.push(e.customer_id);
    });
    const ctnorders = [];
    filteredData.forEach((e) => {
      ctnorders.push(e.ctn);
    });
    setCTNOrder(ctnorders);
    const cbmorders = [];
    filteredData.forEach((e) => {
      cbmorders.push(e.cbm);
    });
    setCBM(cbmorders);
    const tcbmorders = [];
    filteredData.forEach((e) => {
      tcbmorders.push(e.t_cbm);
    });
    setTCBM(tcbmorders);

    const nworders = [];
    filteredData.forEach((e) => {
      nworders.push(e.n_w);
    });
    setNW(nworders);
    const desc1orders = [];
    filteredData.forEach((e) => {
      desc1orders.push(e.description1);
    });
    setDescription1(desc1orders);
    const desc2orders = [];
    filteredData.forEach((e) => {
      desc2orders.push(e.description2);
    });
    setDescription2(desc1orders);
    const photoOrders = [];
    filteredData.forEach((e) => {
      photoOrders.push(e.photo);
    });
    setPhoto(photoOrders);
    const statusorders = [];
    filteredData.forEach((e) => {
      statusorders.push(e.status);
    });
    setStatus(statusorders);
    const qty_ctnorders = [];
    filteredData.forEach((e) => {
      qty_ctnorders.push(e.qty_ctn);
    });
    setQtyToCtn(qty_ctnorders);
    const upriceorders = [];
    filteredData.forEach((e) => {
      upriceorders.push(e.u_price);
    });
    setUtoPrice(upriceorders);
    const gworder = [];
    filteredData.forEach((e) => {
      gworder.push(e.g_w);
    });
    setGw(gworder);
    const orderStatus = [];
    filteredData.forEach((e) => {
      orderStatus.push(e.order_status);
    });
    setOrderStatus(orderStatus);
  };
  const handleChangeContainerID = (val) => {
    setSelectedContainerID(val);
  };

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.properties.defaultRowHeight = 80;
    sheet.columns = [
      { header: "Order Code", key: "order_code", width: 10 },
      { header: "Our Item", key: "our_item", width: 20 },
      { header: "Item Number", key: "item_number", width: 20 },
      { header: "Status", key: "status", width: 20 },
      // { header: "Checked", key: "Checked", width: 20 },
      { header: "Description English", key: "description2", width: 20 },
      { header: "ctn", key: "ctn", width: 20 },
      { header: "qty_ctn", key: "qty_ctn", width: 20 },
      { header: "t_qty", key: "t_qty", width: 10 },
      { header: "cbm", key: "cbm", width: 10 },
      { header: "t_cbm", key: "t_cbm", width: 10 },
      { header: "n_w", key: "n_w", width: 10 },
      { header: "g_w", key: "g_w", width: 10 },
      { header: "notes", key: "notes", width: 30 },
      { header: "photo", key: "photo", width: 20 },
    ];
    const promise = Promise.all(
      orders?.map(async (product, index) => {
        const rowNumber = index + 1;
        sheet.addRow({
          order_code: product?.order_code,
          our_item: product?.our_item,
          item_number: product?.item_number,
          status: product?.status,
          description2: product?.description2,
          ctn: product?.ctn,
          qty_ctn: product?.qty_ctn,
          t_qty: product?.t_qty,
          cbm: product?.cbm,
          t_cbm: product?.t_cbm,
          n_w: product?.n_w,
          g_w: product?.g_w,
          notes: product?.notes,
        });
        const result = await toDataURL(product?.photo);
        const splitted = product?.photo.split(".");
        const extName = splitted[splitted.length - 1];
        let imageID2;
        if (product?.photo === "https://trust-cargo.co/anas_backend/storage/image/") {
          imageID2 = workbook.addImage({
            base64: close,
            extension: "jpg",
          });
        } else {
          imageID2 = workbook.addImage({
            base64: result.base64Url,
            extension: extName,
          });
        }
        // const imageId2 = workbook.addImage({
        //   base64: result.base64Url,
        //   extension: extName,
        // });

        sheet.addImage(imageID2, {
          tl: { col: 13, row: rowNumber },
          ext: { width: 100, height: 100 },
        });
      })
    );
    promise.then(() => {
      const priceCol = sheet.getColumn(5);

      // iterate over all current cells in this column
      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        if (cellValue > 50 && cellValue < 1000) {
          sheet.getCell(cell?.address).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF0000" },
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "sheet.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <div style={{ zIndex: "3" }}>
          <AppBar position="fixed" style={{ backgroundColor: "white" }} className="shadow">
            <Toolbar>
              <div className="row" style={{ width: "2000px", margin: "", color: "", display: "" }}>
                <div className="d-flex" style={{ marginTop: "1rem", width: "100%" }}>
                  <div
                    onClick={() => setDialogOpen(true)}
                    style={{
                      color: "#17c1e8",
                      cursor: "pointer",
                      width: "10px",
                      marginRight: "3rem",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-arrow-left-square"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />
                    </svg>
                  </div>
                  <h2 style={{ marginLeft: "0.5rem", marginRight: "6rem" }}>All Orders Details</h2>
                  <div className="column" style={{ display: "flex" }}>
                    <div className="col-md-3 me-1">
                      <div className="form-group">
                        <label
                          className="control-label"
                          htmlFor="name"
                          style={{ fontSize: "15px" }}
                        >
                          Total CTN
                        </label>
                        <input
                          required
                          id="shop_no2"
                          type="text"
                          className="form-control"
                          name="name"
                          readOnly
                          placeholder="Total CTN"
                          style={{ width: "150px", minWidth: "150px" }}
                          // onChange={(e) => setShopNumber2(e.target.value)}
                          value={total_neCTN.toFixed(2)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  me-1">
                      <div className="form-group">
                        <label
                          className="control-label"
                          htmlFor="name"
                          style={{ fontSize: "15px", marginTop: "" }}
                        >
                          Total G.w
                        </label>
                        <input
                          required
                          id="shop_no2"
                          type="text"
                          className="form-control"
                          name="name"
                          readOnly
                          // onChange={(e) => setShopNumber2(e.target.value)}
                          value={total_gw.toFixed(2)}
                          style={{ width: "150px", minWidth: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  me-1">
                      <div className="form-group">
                        <label
                          className="control-label"
                          htmlFor="name"
                          style={{ fontSize: "15px", marginTop: "" }}
                        >
                          To. T_CBM
                        </label>
                        <input
                          required
                          id="shop_no2"
                          type="text"
                          className="form-control"
                          name="name"
                          readOnly
                          // onChange={(e) => setShopNumber2(e.target.value)}
                          value={total_t_cbm.toFixed(2)}
                          style={{ width: "150px", minWidth: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3  me-1">
                      <div className="form-group">
                        <label
                          className="control-label"
                          htmlFor="name"
                          style={{ fontSize: "15px", marginTop: "" }}
                        >
                          Total Amount
                        </label>
                        <input
                          required
                          id="shop_no2"
                          type="text"
                          className="form-control"
                          name="name"
                          readOnly
                          // onChange={(e) => setShopNumber2(e.target.value)}
                          value={total_amount.toFixed(2)}
                          style={{ width: "150px", minWidth: "150px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      marginLeft: "10px",
                      marginTop: "30px",
                      backgroundColor: "white",
                      display: "",
                      width: "30%",
                    }}
                  >
                    <div
                      className="form-group  col-md-4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CFormSelect
                        id="containerId"
                        onChange={(e) => handleChangeContainer(e.target.value)}
                      >
                        <option value="" selected="">
                          Filter containers
                        </option>
                        {containers.map((user) => (
                          <option value={user.id}>{user.name}</option>
                        ))}
                      </CFormSelect>
                    </div>
                    <div
                      className="form-group  col-md-4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CFormSelect
                        id="containerId"
                        onChange={(e) => handleChangeCustomer(e.target.value)}
                      >
                        <option value="" selected="">
                          Filter customers
                        </option>
                        {customers.map((user1) => (
                          <option value={user1.code} key={user1.code}>
                            {user1.name}
                          </option>
                        ))}
                      </CFormSelect>
                    </div>
                    <div
                      className="form-group  col-md-4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CFormSelect
                        id="containerId"
                        onChange={(e) => handleChangeStatus(e.target.value)}
                      >
                        <option value="" selected="">
                          Filter status
                        </option>
                        <option value="new">New</option>
                        <option value="received">Received</option>
                      </CFormSelect>
                    </div>{" "}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    className="form-group  col-md-2"
                    style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                  >
                    <CFormSelect
                      id="containerId"
                      required
                      onChange={(e) => handleChangeContainerID(e.target.value)}
                    >
                      <option value="" selected="">
                        Select a container
                      </option>
                      {containers.map((user) => (
                        <option value={user.id}>{user.name}</option>
                      ))}
                    </CFormSelect>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      marginTop: "1rem",
                      marginBottom: "0.5rem",
                      marginRight: "0.5rem",
                      marginLeft: "0.5rem",
                      color: "white",
                    }}
                  >
                    <ConfirmOrder
                      order_id1={visibleOrderIDs}
                      id={visibleIDs}
                      containerid={selectedContainerID}
                      order_code1={visibleOrderCode}
                      our_item1={visibleItem}
                      ItemArray={visibleItem}
                      photo={visibleOrderPhoto}
                      desc1={visibleDesc1}
                      desc2={visibleDesc2}
                      ctn={visibleCTNOrders}
                      qtytoctn={visibleQTYTOCTNOrders}
                      totoqty={visibleTtoQTYOrders}
                      utoprice={visibleUToPriceOrders}
                      amount={visibleAmountOrders}
                      cbm={visibleCBMOrders}
                      ttocbm={visibleTCBMOrders}
                      nw={visibleNWOrders}
                      gw={visibleGWOrders}
                      status={visibleStatusOrders}
                      notes={visibleNotesOrders}
                      customer_id={visibleCustomers}
                    />
                  </div>{" "}
                  <div className="form-group" style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
                    <Button
                      variant="contained"
                      style={{ color: "white" }}
                      onClick={exportExcelFile}
                    >
                      Export to Excel
                    </Button>
                  </div>
                </div>
              </div>{" "}
            </Toolbar>
          </AppBar>{" "}
        </div>
        <Card style={{ paddingTop: "5rem", paddingBottom: "5rem" }}>
          {loading ? (
            <div
              style={{
                width: "1000",
                height: "50px",
                display: "flex",
                justifyContent: "start",
                marginTop: "100px",
              }}
            >
              <progress className="pure-material-progress-circular" />
            </div>
          ) : (
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table
                sx={{ overflow: "scroll", display: "block", marginTop: "5rem" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    {[
                      { label: "O. Code", width: "110px", align: "start" },
                      { label: "Container", width: "100px", align: "start" },
                      { label: "Our Item", width: "100px", align: "center" },
                      { label: "Item NO.", width: "100px", align: "center" },
                      { label: "Photo", width: "100px", align: "center" },
                      { label: "Desc En.", width: "200px", align: "center" },
                      { label: "CTN", width: "70px", align: "center" },
                      { label: "QTY/CTN", width: "50px", align: "center" },
                      { label: "T/QTY", width: "50px", align: "center" },
                      { label: "Uprice", width: "50px", align: "center" },
                      { label: "CBM", width: "60px", align: "center" },
                      { label: "T/CBM", width: "70px", align: "center" },
                      { label: "N.W", width: "70px", align: "center" },
                      { label: "G.W", width: "70px", align: "center" },
                      { label: "Status", width: "100px", align: "center" },
                      { label: "O.Status", width: "100px", align: "center" },
                      { label: "Notes", width: "180px", align: "start" },
                    ].map((header, index) => (
                      <Box key={index}>
                        <TableCell
                          sx={{ width: header.width, minWidth: header.width }}
                          align={header.align}
                        >
                          {header.label}
                        </TableCell>
                      </Box>
                    ))}
                  </TableRow>
                </TableHead>
                {orderFiltered && orderFiltered.length > 0 ? (
                  <TableBody style={{ display: "block " }}>
                    {orderFiltered.map((vla, index) => (
                      <TableRow key={index} sx={{ minWidth: "100px" }}>
                        <Box style={{ overflowX: "auto" }}>
                          <TableCell
                            sx={{ width: "10px", minWidth: "10px", padding: "3px" }}
                            align="start"
                          >
                            <Checkbox
                              checked={checked[index]}
                              // onChange={() => deleteObjectFromArray(index)}
                              onChange={() => handleCheckBoxChange(index)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "100px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              onChange={(e) => handleOrderCodeChange(e, index)}
                              value={ordercode2[index]}
                              type="text"
                              style={{ width: "100px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder="Order Code"
                            />
                            {/* <Button onClick={() => deleteObjectFromArray(index)}>delete</Button> */}
                          </TableCell>
                          <TableCell
                            sx={{ width: "100px", minWidth: "50px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              // onChange={(e) => handleItemChange(e, index)}
                              value={vla.container_name}
                              type="text"
                              style={{ width: "100px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="Our Item"
                            />
                            {/* <Button onClick={() => deleteObjectFromArray(index)}>delete</Button> */}
                          </TableCell>
                          <TableCell
                            sx={{ width: "100px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              onChange={(e) => handleItemChange(e, index)}
                              value={itemarray[index]}
                              type="text"
                              style={{ width: "100px", minWidth: "90px" }}
                              className="form-control"
                              name="name"
                              placeholder="Our Item"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "100px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              id="item"
                              required
                              onChange={(e) => handleItemChange(e, index)}
                              value={itemarray[index]}
                              type="text"
                              style={{ width: "100px", minWidth: "90px" }}
                              className="form-control"
                              name="name"
                              placeholder="Item Number"
                            />
                          </TableCell>

                          <TableCell
                            sx={{ width: "100px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            {photo[index] === "https://trust-cargo.co/anas_backend/storage/" ? (
                              <img src={image} style={{ width: "100px" }} alt="Default Image" />
                            ) : (
                              <img
                                src={photo[index]}
                                style={{ width: "90px", height: "60px" }}
                                alt=""
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ width: "200px", minWidth: "210px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              id="desc2"
                              type="text"
                              style={{ width: "200px", minWidth: "200px" }}
                              className="form-control"
                              name="name"
                              placeholder="Desc English"
                              onChange={(e) => handleDesc2Change(e, index)}
                              value={description2[index]}
                            />
                          </TableCell>

                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "70px", minWidth: "70px", marginLeft: "10px" }}
                              className="form-control"
                              name="name"
                              placeholder="CTN"
                              onChange={(e) => handleCTNChange(e, index)}
                              value={ctnorders1[index]}
                              id="ctn"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "50px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "50px", minWidth: "50px" }}
                              className="form-control"
                              name="name"
                              placeholder="QTY/CTN"
                              onChange={(e) => handleQTYTOCTNChange(e, index)}
                              value={qtytoctn[index]}
                              id="qtytoctn"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "50px", minWidth: "50px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "50px", minWidth: "50px" }}
                              className="form-control"
                              name="name"
                              readOnly
                              placeholder="T/QTY"
                              // onChange={(e) => handleTTOQTYChange(e, index)}
                              value={ttoqty[index]}
                              id="ttoqty"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "50px", minWidth: "50px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              id="desc2"
                              type="text"
                              style={{ width: "50px", minWidth: "50px" }}
                              className="form-control"
                              name="name"
                              placeholder="UPrice"
                              onChange={(e) => handleUTOPriceChange(e, index)}
                              value={utoprice[index]}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "60px", minWidth: "60px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "60px", minWidth: "60px" }}
                              className="form-control"
                              name="name"
                              placeholder="CBM"
                              onChange={(e) => handleCBMChange(e, index)}
                              value={cbm[index]}
                              id="cbm"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "100px", minWidth: "100px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "100px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder="T/CBM"
                              readOnly
                              // onChange={(e) => handleTTOCBMChange(e, index)}
                              value={t_cbmorders[index]}
                              id="ttocbm"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="N.W"
                              onChange={(e) => handleNWChange(e, index)}
                              value={nw[index]}
                              id="nw"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "70px", minWidth: "70px" }}
                              className="form-control"
                              name="name"
                              placeholder="G.W"
                              readOnly
                              // onChange={(e) => handleGWChange(e, index)}
                              value={gw[index]}
                              id="gw"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "100px", minWidth: "100px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "100px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder="Status"
                              onChange={(e) => handleStatusChange(e, index)}
                              value={status[index]}
                              id="status"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ width: "100px", minWidth: "100px", padding: "2px" }}
                            align="start"
                          >
                            <input
                              required
                              type="text"
                              style={{ width: "100px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder="O.Status"
                              // onChange={(e) => handleStatusChange(e, index)}
                              value={order_status[index]}
                              id="order status"
                            />
                          </TableCell>
                          <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                            <input
                              required
                              type="text"
                              style={{ width: "100px", minWidth: "100px" }}
                              className="form-control"
                              name="name"
                              placeholder="Notes"
                              onChange={(e) => handleNotesChange(e, index)}
                              value={notes[index]}
                              id="notes"
                            />
                          </TableCell>
                        </Box>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 2 }} align="center">
                        There is no data to display
                      </TableCell>
                    </Box>
                  </TableBody>
                )}
              </Table>
            </SuiBox>
          )}
        </Card>

        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure you want to go back? </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                navigate("/containers");
              }}
              autoFocus
            >
              YES
            </Button>
            <Button onClick={() => setDialogOpen(false)} autoFocus>
              NO
            </Button>
          </DialogActions>
        </Dialog>
      </SuiBox>
    </>
  );
}

export default AllOrders;
