/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import TextField from "@material-ui/core/TextField";
// import Box from "@material-ui/core/Box";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useEffect, useState } from "react";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditContainerSuccess from "./edit_dialog";

function EditContainer() {
  const route = useLocation().pathname.split("/").slice(1);
  const { t } = useTranslation();
  const [name, setName] = useState([]);
  const [number, setNumber] = useState([]);
  const [status, setStatus] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loadingDate, setLoadingDate] = useState([]);
  const [estimatedArrivalDate, setEstimatedArrivalDate] = useState([]);
  const [telexStatus, setTelexStatus] = useState([]);

  const fetchData = async () => {
    console.log(route[route.length - 1]);
    const token = sessionStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}`, ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_container_data/${route[route.length - 1]}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setName(data["container"]["name"]);
    setNumber(data["container"]["number"]);
    setStatus(data["container"]["status"]);
    setNotes(data["container"]["notes"]);
    setLoadingDate(data["container"]["loading_date"]);
    setEstimatedArrivalDate(data["container"]["estimated_arrival_date"]);
    setTelexStatus(data["container"]["telex_status"]);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const changeName = (event) => {
    setName(event.target.value);
  };
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Edit Container #{route[route.length - 1]}</SuiTypography>
            </SuiBox> */}
            <div style={{ width: "400px", margin: "20px" }}>
              {/* <div className="form-group  col-md-12 ">
                <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div> */}
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Number
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Number"
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="status"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Status
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="unloaded">Unloaded</option>
                  <option value="on progress">On progress</option>
                  <option value="delivered">Delivered</option>
                </select>
              </div>

              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Notes
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="notes"
                  placeholder="Notes"
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  loading date
                </label>
                <input
                  required
                  type="date"
                  className="form-control"
                  name="loading date"
                  placeholder="loading date"
                  onChange={(e) => setLoadingDate(e.target.value)}
                  value={loadingDate}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Estimated arrival date
                </label>
                <input
                  required
                  type="date"
                  className="form-control"
                  name="Estimated arrival date"
                  placeholder="Estimated arrival date"
                  onChange={(e) => setEstimatedArrivalDate(e.target.value)}
                  value={estimatedArrivalDate}
                />
              </div>
              <div className="form-group  col-md-12 ">
                <label
                  className="control-label"
                  htmlFor="name"
                  style={{ fontSize: "15px", marginTop: "20px" }}
                >
                  Telex status
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => setTelexStatus(e.target.value)}
                >
                  <option value="released">Released</option>
                  <option value="not_yet">Not Yet</option>
                </select>
              </div>

              <EditContainerSuccess
                name1={name}
                number1={number}
                status1={status}
                notes1={notes}
                loadingDate1={loadingDate}
                estimatedArrivalDate1={estimatedArrivalDate}
                telexStatus1={telexStatus}
              />
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditContainer;
