/* eslint-disable camelcase */
/* eslint-disable dot-notation */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import { useTranslation } from "react-i18next";
// import DeleteLab from "./delete_lab";
import Checkbox from "@mui/material/Checkbox";

function CustomerOrders() {
  const [orders, setOrders] = useState([]);
  const [containers, setContainers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setloading] = useState(false);
  const [checked, setChecked] = useState([false]);
  const [totalCTN, setTotalCTN] = useState(0);
  const [totalCBM, setTotalCBM] = useState(0);
  const [totalGW, setTotalGW] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedContainer, setSelectedContainer] = useState("");
  const [selectedContainerStatus, setSelectedContainerStatus] = useState();
  const handleCheckChange = (event, index) => {
    setChecked((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.checked : el;
      })
    );
  };
  // const { t } = useTranslation();
  const fetchData = async () => {
    const customer_code = sessionStorage.getItem("customer_code");
    const headers = { ContentType: "application/json", Accept: "application/json" };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/customer_orders/${customer_code}`,
      {
        headers,
      }
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["orders"]);
    console.log(data["orders"]);

    setloading(false);
  };
  const getContainers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/containers");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setContainers(data["containers"]);
  };
  const getCustomers = async () => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/customers");
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    // setCustomers((array) => [...array, data["customers"]]);
    setCustomers([data["customers"]]);
  };

  const getCustomersID = async (id) => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/filter_orders_customer_id/${id}`
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["orders"]);
  };
  const getContainersID = async (id) => {
    // const token = sessionStorage.getItem("token");
    // const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/filter_orders_container_id/${id}`
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["orders"]);
  };
  const filterItem = orders.filter((order) => {
    if (!selectedContainer || selectedContainer === "all") {
      return true;
    }
    return order.container_id === selectedContainer;
  });

  useEffect(() => {
    setloading(true);
    fetchData();
    getContainers();
    getCustomers();
  }, []);
  // const handleChangeContainer = (event) => {
  //   getContainersID(event.target.value);
  // };
  // const handleChangeCustomer = (event) => {
  //   getCustomersID(event.target.value);
  // };
  useEffect(() => {
    // Calculate totals when users array changes
    let ctnTotal = 0;
    let cbmTotal = 0;
    let gwTotal = 0;
    let amountTotal = 0;

    filterItem.forEach((user, index) => {
      ctnTotal += parseFloat(user.total_ctn) || 0;
      cbmTotal += parseFloat(user.total_t_cbm) || 0;
      gwTotal += parseFloat(user.total_gw) || 0;
      amountTotal += parseFloat(user.total_amount) || 0;
    });

    // Update state variables
    setTotalCTN(ctnTotal);
    setTotalCBM(cbmTotal);
    setTotalGW(gwTotal);
    setTotalAmount(amountTotal);
  }, [filterItem]);
  const customerID = sessionStorage.getItem("customer_code");
  async function changeContainer(ID) {
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/filter_order_details_container_id/AAF/${ID}`,
      {
        headers,
      }
    );
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setOrders(data["order_details"]);
  }
  const handleChange = (event) => {
    const newSelectedContainer = event.target.value;
    console.log("Selected container:", newSelectedContainer); // Debugging
    setSelectedContainer(newSelectedContainer);
    const selectContainer = containers.find((item) => item.id === Number(newSelectedContainer));
    if (selectContainer) {
      setSelectedContainerStatus(selectContainer.status);
    }
  };
  const customerName = sessionStorage.getItem("customer_name");

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">{customerName}</SuiTypography>
            </SuiBox>
            <div style={{ display: "flex", flexDirection: "row", width: "500px" }}>
              <div className="form-group  col-md-4" style={{ marginLeft: "10px" }}>
                <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                  Filter Containers
                </label>
                <select
                  id="shop"
                  className="form-control"
                  style={{ height: "40px" }}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="all">All</option>
                  {containers.map((user) => (
                    <option value={user.id}>{user.name}</option>
                  ))}
                </select>
              </div>
              <div
                className="form-group  col-md-6"
                style={{ fontSize: "17px", marginTop: "45px", marginLeft: "5px" }}
              >
                <span>Container Status: </span>
                {selectedContainerStatus}
              </div>
              <div className="col-md-3">
                <div className="form-group" style={{ marginLeft: "10px" }}>
                  <label className="control-label" htmlFor="name" style={{ fontSize: "15px" }}>
                    Total T_CTN
                  </label>
                  <input
                    required
                    id="shop_no2"
                    type="text"
                    className="form-control"
                    name="name"
                    readOnly
                    placeholder="Total CTN"
                    style={{ width: "70px", minWidth: "70px" }}
                    // onChange={(e) => setShopNumber2(e.target.value)}
                    value={totalCTN}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="name"
                    style={{ fontSize: "15px", marginTop: "" }}
                  >
                    Total T_G.w
                  </label>
                  <input
                    required
                    id="shop_no2"
                    type="text"
                    className="form-control"
                    name="name"
                    readOnly
                    // onChange={(e) => setShopNumber2(e.target.value)}
                    value={totalGW}
                    style={{ width: "70px", minWidth: "70px" }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="name"
                    style={{ fontSize: "15px", marginTop: "" }}
                  >
                    Total T_CBM
                  </label>
                  <input
                    required
                    id="shop_no2"
                    type="text"
                    className="form-control"
                    name="name"
                    readOnly
                    // onChange={(e) => setShopNumber2(e.target.value)}
                    value={totalCBM}
                    style={{ width: "70px", minWidth: "70px" }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="name"
                    style={{ fontSize: "15px", marginTop: "" }}
                  >
                    Total T_Amount
                  </label>
                  <input
                    required
                    id="shop_no2"
                    type="text"
                    className="form-control"
                    name="name"
                    readOnly
                    // onChange={(e) => setShopNumber2(e.target.value)}
                    value={totalAmount}
                    style={{ width: "70px", minWidth: "70px" }}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  width: "1000",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <progress className="pure-material-progress-circular" />
              </div>
            ) : (
              <Table sx={{ minWidth: 1000 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Order Code
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Delivery Date
                      </TableCell>{" "}
                    </Box>

                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Container
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Status
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Total Amount
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Total CTN
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Total G.W
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                        Total T/CBM
                      </TableCell>
                    </Box>
                  </TableRow>
                </TableHead>
                {filterItem && filterItem.length > 0 ? (
                  <TableBody>
                    {filterItem.map((user, index) => (
                      <TableRow
                        key={user.name}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },
                          display: "flex",
                        }}
                      >
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {user.order_code ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {user.delivery_date ?? "-"}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {user.container_name ?? ""}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {user.status ?? ""}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {Number(user.total_amount).toFixed(3) ?? ""}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {Number(user.total_ctn).toFixed(3) ?? ""}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {Number(user.total_gw).toFixed(3) ?? ""}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: window.innerWidth / 9 }} align="center">
                            {Number(user.total_t_cbm).toFixed(3) ?? ""}
                          </TableCell>
                        </Box>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 2 }} align="center">
                        There is no data to display
                      </TableCell>
                    </Box>
                  </TableBody>
                )}
              </Table>
            )}

            {/* </TableContainer> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CustomerOrders;
