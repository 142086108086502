/* eslint-disable prettier/prettier */
/* eslint-disable no-dupe-keys */
/* eslint-disable object-shorthand */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import PropTypes from "prop-types";

export default function ConfirmOrder({
  order_id1,
  order_code1,
  our_item1,
  // ItemArray,
  photo,
  desc1,
  desc2,
  // category1,
  ctn,
  qtytoctn,
  totoqty,
  utoprice,
  amount,
  cbm,
  ttocbm,
  nw,
  gw,
  status,
  notes,
  id,
  containerid,
  customer_id,
}) {
  ConfirmOrder.propTypes = {
    customer_id: PropTypes.instanceOf(Array).isRequired,
    order_id1: PropTypes.instanceOf(Array).isRequired,
    order_code1: PropTypes.instanceOf(Array).isRequired,
    containerid: PropTypes.instanceOf(String).isRequired,
    our_item1: PropTypes.instanceOf(Array).isRequired,
    // ItemArray: PropTypes.instanceOf(Array).isRequired,
    id: PropTypes.instanceOf(Array).isRequired,
    photo: PropTypes.instanceOf(Array).isRequired,
    desc1: PropTypes.instanceOf(Array).isRequired,
    desc2: PropTypes.instanceOf(Array).isRequired,
    // category1: PropTypes.instanceOf(Array).isRequired,
    ctn: PropTypes.instanceOf(Array).isRequired,
    qtytoctn: PropTypes.instanceOf(Array).isRequired,
    totoqty: PropTypes.instanceOf(Array).isRequired,
    utoprice: PropTypes.instanceOf(Array).isRequired,
    amount: PropTypes.instanceOf(Array).isRequired,
    cbm: PropTypes.instanceOf(Array).isRequired,
    ttocbm: PropTypes.instanceOf(Array).isRequired,
    nw: PropTypes.instanceOf(Array).isRequired,
    gw: PropTypes.instanceOf(Array).isRequired,
    status: PropTypes.instanceOf(Array).isRequired,
    notes: PropTypes.instanceOf(Array).isRequired,
  };
  const [dialogsucesss, setOpen1] = React.useState(false);
  const [dialogempty, setOpen2] = React.useState(false);
  const [dialogloading, setOpen3] = React.useState(false);
  const [dialogcontainerempty, setOpen4] = React.useState(false);

  const handleClickOpenLoading = () => {
    setOpen3(true);
  };
  const handleCloseLoading = () => {
    setOpen3(false);
  };
  const { t } = useTranslation();

  const handleClickOpenSuccess = () => {
    setOpen1(true);
  };
  const handleClickOpenEmpty = () => {
    setOpen2(true);
  };

  const handleCloseSuccess = () => {
    setOpen1(false);
  };
  const handleCloseEmpty = () => {
    setOpen2(false);
  };
  // console.log("order_id1:", order_id1);
  // console.log("order_code1:", order_code1);
  // console.log("containerid:", containerid);
  // console.log("our_item1:", our_item1);
  // console.log("id:", id);
  // console.log("photo:", photo);
  // // console.log("desc1:", desc1);
  // console.log("desc2:", desc2);
  // // console.log("category1:", category1);
  // console.log("ctn:", ctn);
  // console.log("qtytoctn:", qtytoctn);
  // console.log("totoqty:", totoqty);
  // console.log("utoprice:", utoprice);
  // console.log("amount:", amount);
  // console.log("cbm:", cbm);
  // console.log("ttocbm:", ttocbm);
  // console.log("nw:", nw);
  // console.log("gw:", gw);
  // console.log("status:", status);
  // console.log("notes:", notes);
  // console.log("customer_id:", customer_id);
  // console.log("containerid:", containerid);
  function EditOrder() {
    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/confirm_order";
    const formdata = new FormData();
    // ItemArray.map((item, index) => formdata.append(`our_item[${index}]`, ItemArray[index]));
    formdata.append(`container_id`, containerid);
    customer_id.map((item, index) => formdata.append(`customer_id[${index}]`, customer_id[index]));

    our_item1.map((item, index) => formdata.append(`our_item[${index}]`, our_item1[index]));
    our_item1.map((item, index) => formdata.append(`item_number[${index}]`, our_item1[index]));
    id.map((item, index) => formdata.append(`id[${index}]`, id[index]));
    order_code1.map((item, index) => formdata.append(`order_code[${index}]`, order_code1[index]));
    order_id1.map((item, index) => formdata.append(`order_id[${index}]`, order_id1[index]));

    // desc1.map((item, index) => formdata.append(`description1[${index}]`, "p"));
    desc2.map((item, index) => formdata.append(`description2[${index}]`, desc2[index]));
    // category.map((item, index) => formdata.append(`category[${index}]`, category[index]));
    ctn.map((item, index) => formdata.append(`ctn[${index}]`, ctn[index]));
    amount.map((item, index) => formdata.append(`amount[${index}]`, amount[index]));
    cbm.map((item, index) => formdata.append(`cbm[${index}]`, cbm[index]));
    status.map((item, index) => formdata.append(`status[${index}]`, status[index]));
    notes.map((item, index) => formdata.append(`notes[${index}]`, notes[index]));
    qtytoctn.map((item, index) => formdata.append(`qty_ctn[${index}]`, qtytoctn[index]));
    totoqty.map((item, index) => formdata.append(`t_qty[${index}]`, totoqty[index]));
    utoprice.map((item, index) => formdata.append(`u_price[${index}]`, utoprice[index]));
    ttocbm.map((item, index) => formdata.append(`t_cbm[${index}]`, ttocbm[index]));
    nw.map((item, index) => formdata.append(`n_w[${index}]`, nw[index]));
    gw.map((item, index) => formdata.append(`g_w[${index}]`, gw[index]));
    // totoqty.map((item, index) => formdata.append(`t_qty[${index}]`, totoqty[index]));

    setOpen3(true);
    axios
      .post(url, formdata, headers)
      .then((response) => {
        console.log("success");
        // console.log(formData);
        handleCloseLoading();
        handleClickOpenSuccess();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const navigate = useNavigate();
  return (
    <div >
      <Button
        variant="contained"
        style={{ color: "white" }}
        onClick={() => {
          if (document.getElementById("containerid").value === "") {
            setOpen4(true);
          } else if (status.indexOf("new") > -1) {
            handleClickOpenLoading();
            handleCloseLoading();
            handleClickOpenEmpty();
          } else {
            EditOrder();
          }
        }}
      >
        Confirm Order
      </Button>
      <Dialog
        open={dialogsucesss}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">تم تأكيد الطلبات بنجاح</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* navigate("/add_user"); */}
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/orders");
            }}
            autoFocus
          >
            حسنا
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogempty}
        onClose={handleCloseEmpty}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          تم استلام بعض الطلبات أو قم بتأكيد الحالة{" "}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={handleCloseEmpty} autoFocus>
            حسنا
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogcontainerempty}
        onClose={() => setOpen4(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Container!</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button onClick={() => setOpen4(false)} autoFocus>
            حسنا
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogloading}
        onClose={handleCloseLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ReactLoading color="blue" height={50} width={50} type="spokes" />
      </Dialog>
    </div>
  );
}
