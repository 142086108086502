/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import "react-dropdown/style.css";
import { useEffect, useState } from "react";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import close from "assets/images/close.png";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { CFormInput } from "@coreui/react";
import broken_image from '../../assets/images/image.png';
import EditOrderData from "./fetch";
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/users/data/projectsTableData";

function EditOrder() {
  const route = useLocation().pathname.split("/").slice(1);
  const [orders, setOrders] = useState([]);
  const [photo, setPhoto] = useState([]);

  const getSpeceficOrder = async () => {
    // console.log(route[route.length - 1]);
    // const token = sessionStorage.getItem("token");
    const headers = { ContentType: `application/json` };
    const response = await fetch(
      `https://trust-cargo.co/anas_backend/api/specefic_order/${route[route.length - 1]}`,
      { headers }
    );
    // eslint-disable-next-line dot-notation
    const data = await response.json();
    setOrders(data["order"]["products"]);
    for (let index = 0; index < data["order"]["products"].length; index += 1) {
      photo.push("");
    }
  };
  const [ordercode, setOrderCode] = useState([""]);
  const [container_id, setContainerID] = useState([""]);
  const [itemarray, setItemArray] = useState([""]);
  const [itemnumber, setItemNumber] = useState([""]);
  const [description1, setDescription1] = useState([""]);
  const [description2, setDescription2] = useState([""]);
  const [category, setCategory] = useState([""]);
  const [ctn, setCTN] = useState([]);
  const [qtytoctn, setQtyToCtn] = useState([""]);
  const [utoprice, setUtoPrice] = useState([""]);
  const [cbm, setCBM] = useState([""]);
  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);
  // const [amount, setAmount] = useState([""]);
  // const [amount, setAmount] = useState([""]);
  // eslint-disable-next-line arrow-body-style

  const ttocbm = cbm.map((e, index) => {
    return e * ctn[index];
  });

  // const total_cbm = ttocbm.reduce((e1, e2) => {
  //   return e1 + e2;
  // });
  // const total_ctn = ctn.reduce((e1, e2) => {
  //   return e1 + e2;
  // });

  const ttoqty = ctn.map((e, index) => {
    return e * qtytoctn[index];
  });
  const amount = utoprice.map((e, index) => {
    return e * ttoqty[index];
  });

  // const total_amount = amount.reduce((e1, e2) => {
  //   return e1 + e2;
  // });
  // const total_items = itemarray.reduce((e1, e2) => {
  //   return e1 + e2;
  // });

  // const [ttocbm, setTtoCBM] = useState([""]);
  const [nw, setNW] = useState([""]);
  // const [gw, setGW] = useState([""]);
  const [status, setStatus] = useState([""]);
  const [notes, setNotes] = useState([""]);

  const handlePhotoChange = (event, index) => {
    setPhoto((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.files[0] : el;
      })
    );
  };

  const gw = nw.map((e, index) => {
    return e * ctn[index];
  });
  const handleOrderCodeChange = (event, index) => {
    setOrderCode((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleContainerIDChange = (event, index) => {
    setContainerID((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  const handleItemChange = (event, index) => {
    setItemArray((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleItemNumberChange = (event, index) => {
    setItemNumber((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDescChange = (event, index) => {
    setDescription1((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleDesc2Change = (event, index) => {
    setDescription2((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCategoryChange = (event, index) => {
    setCategory((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCTNChange = (event, index) => {
    setCTN((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleQTYTOCTNChange = (event, index) => {
    setQtyToCtn((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleUTOPriceChange = (event, index) => {
    setUtoPrice((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCBMChange = (event, index) => {
    setCBM((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleNWChange = (event, index) => {
    setNW((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleGWChange = (event, index) => {
  //   setGW((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleStatusChange = (event, index) => {
    setStatus((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleNotesChange = (event, index) => {
    setNotes((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleAmountChange = (event, index) => {
  //   setAmount((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const total_gw =
    gw.length !== 0
      ? gw.reduce((e1, e2) => {
          return Number(e1) + Number(e2);
        })
      : "0";
  const total_ctn =
    ctn.length !== 0
      ? ctn.reduce((e1, e2) => {
          return Number(e1) + Number(e2);
        })
      : "0";

  const total_amount =
    amount.length !== 0
      ? amount.reduce((e1, e2) => {
          return Number(e1) + Number(e2);
        })
      : "0";
  useEffect(() => {
    getSpeceficOrder();
  }, []);
  useEffect(() => {
    const ordercode1 = [];
    orders.forEach((e) => {
      ordercode1.push(e.order_code);
    });
    const container_id1 = [];
    orders.forEach((e) => {
      container_id1.push(e.container_id);
    });
    const ite1 = [];
    orders.forEach((e) => {
      ite1.push(e.item_number);
    });
    const cate = [];
    orders.forEach((e) => {
      cate.push(e.category);
    });
    const ctnorders = [];
    orders.forEach((e) => {
      ctnorders.push(e.ctn);
    });
    const qtytoctnorders = [];
    orders.forEach((e) => {
      qtytoctnorders.push(e.qty_ctn);
    });

    const desc1 = [];
    orders.forEach((e) => {
      desc1.push(e.description1);
    });
    const desc2 = [];
    orders.forEach((e) => {
      desc2.push(e.description2);
    });
    const utopriceorders = [];
    orders.forEach((e) => {
      utopriceorders.push(e.u_price);
    });
    const cbmorders = [];
    orders.forEach((e) => {
      cbmorders.push(e.cbm);
    });

    const nworders = [];
    orders.forEach((e) => {
      nworders.push(e.n_w);
    });

    const statusorders = [];
    orders.forEach((e) => {
      statusorders.push(e.status);
    });
    const notesorders = [];
    orders.forEach((e) => {
      notesorders.push(e.notes);
    });
    setOrderCode(ordercode1);
    setContainerID(container_id1);
    setItemArray(ite1);
    setCategory(cate);
    setCTN(ctnorders);
    setQtyToCtn(qtytoctnorders);
    setDescription1(desc1);
    setDescription2(desc2);
    setUtoPrice(utopriceorders);
    setCBM(cbmorders);
    setNW(nworders);
    setStatus(statusorders);
    setNotes(notesorders);
  }, [orders]);

  const handleImageChange = (e) => {
    const fies = Array(e.target.files.length).fill("");
    setFiles(fies.map((item, index) => URL.createObjectURL(e.target.files[index])));
    setFiles1(fies.map((item, index) => e.target.files[index]));
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Edit Order #{route[route.length - 1]}</SuiTypography>
            </SuiBox> */}
            <div className="row">
              <div className="form-group col-md-4">
                <EditOrderData
                  order_id1={route[route.length - 1]}
                  order_code1={ordercode}
                  our_item1={itemarray}
                  ItemArray={itemarray}
                  photo={photo}
                  desc1={description1}
                  desc2={description2}
                  category1={category}
                  ctn={ctn}
                  qtytoctn={qtytoctn}
                  totoqty={ttoqty}
                  utoprice={utoprice}
                  amount={amount}
                  cbm={cbm}
                  ttocbm={ttocbm}
                  nw={nw}
                  gw={gw}
                  status={status}
                  notes={notes}
                  container_id={container_id}
                />
              </div>
            </div>

            {/* <div style={{ width: "1000px", marginLeft: "20px", marginTop: "10px", color: "green" }}> */}
            <SuiBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table sx={{ overflow: "scroll", display: "block" }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ display: "flex" }}>
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                        O. Code
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                        Container
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                        Our Item
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                        Item NO.
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="center">
                        Photo
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "200px", minWidth: "200px" }} align="center">
                        Desc En.
                      </TableCell>
                    </Box>

                    <Box>
                      <TableCell sx={{ width: "70px", minWidth: "70px" }} align="center">
                        CTN
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "50px", minWidth: "50px" }} align="center">
                        QTY/CTN
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "50px", minWidth: "50px" }} align="center">
                        T/QTY
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "50px", minWidth: "50px" }} align="center">
                        Uprice
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "60px", minWidth: "60px" }} align="center">
                        CBM
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "70px", minWidth: "70px" }} align="center">
                        T/CBM
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "70px", minWidth: "70px" }} align="center">
                        N.W
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "70px", minWidth: "70px" }} align="center">
                        G.W
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "100px", minWidth: "100px" }} align="center">
                        Status
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: "180px", minWidth: "180px" }} align="start">
                        Notes
                      </TableCell>
                    </Box>
                  </TableRow>
                </TableHead>
                <TableBody style={{ display: "block " }}>
                  {orders.map((vla, index) => (
                    <TableRow key={`${index}key`} sx={{ minWidth: "100px" }}>
                      <Box style={{ overflowX: "auto" }}>
                        <TableCell
                          sx={{ width: "100px", minWidth: "100px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            id="item"
                            required
                            onChange={(e) => handleOrderCodeChange(e, index)}
                            value={ordercode[index]}
                            type="text"
                            style={{ width: "100px", minWidth: "100px" }}
                            className="form-control"
                            name="name"
                            placeholder="Order Code"
                          />
                          {/* <Button onClick={() => deleteObjectFromArray(index)}>delete</Button> */}
                        </TableCell>
                        <TableCell
                          sx={{ width: "100px", minWidth: "100px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            id="item"
                            required
                            // onChange={(e) => handleItemChange(e, index)}
                            value={vla.container_name}
                            type="text"
                            style={{ width: "100px", minWidth: "100px" }}
                            className="form-control"
                            name="name"
                            placeholder="Container name"
                          />
                          {/* <Button onClick={() => deleteObjectFromArray(index)}>delete</Button> */}
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            id="item"
                            required
                            onChange={(e) => handleItemChange(e, index)}
                            value={itemarray[index]}
                            type="text"
                            style={{ width: "90px", minWidth: "90px" }}
                            className="form-control"
                            name="name"
                            placeholder="Our Item"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            id="item"
                            required
                            onChange={(e) => handleItemChange(e, index)}
                            value={itemarray[index]}
                            type="text"
                            style={{ width: "90px", minWidth: "90px" }}
                            className="form-control"
                            name="name"
                            placeholder="Item Number"
                          />
                        </TableCell>

                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <CFormInput
                            type="file"
                            id="categoryPic"
                            feedbackInvalid="Image required"
                            required
                            onChange={(event) => {
                              handlePhotoChange(event, index);
                            }}
                          />
                          <div
                            className="form-group d-flex flex-row  col-md-12 d-grid gap-2"
                            style={{ marginTop: "20px" }}
                          >
                            {typeof photo[index] === "string" ||
                            typeof photo[index] === "undefined" ? (
                              <img
                                src={photo[index]}
                                style={{ height: "60px" }}
                                alt=""
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = broken_image;
                                }}
                              />
                            ) : (
                              <img
                                src={URL.createObjectURL(photo[index])}
                                style={{ height: "60px" }}
                                alt=""
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{ width: "210px", minWidth: "210px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            id="desc2"
                            type="text"
                            style={{ width: "200px", minWidth: "200px" }}
                            className="form-control"
                            name="name"
                            placeholder="Desc English"
                            onChange={(e) => handleDesc2Change(e, index)}
                            value={description2[index]}
                          />
                        </TableCell>

                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="CTN"
                            onChange={(e) => handleCTNChange(e, index)}
                            value={ctn[index]}
                            id="ctn"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="QTY/CTN"
                            onChange={(e) => handleQTYTOCTNChange(e, index)}
                            value={qtytoctn[index]}
                            id="qtytoctn"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            readOnly
                            placeholder="T/QTY"
                            // onChange={(e) => handleTTOQTYChange(e, index)}
                            value={ttoqty[index]}
                            id="ttoqty"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "120px", minWidth: "120px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            id="desc2"
                            type="text"
                            style={{ width: "100px", minWidth: "100px" }}
                            className="form-control"
                            name="name"
                            placeholder="UPrice"
                            onChange={(e) => handleUTOPriceChange(e, index)}
                            value={utoprice[index]}
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="CBM"
                            onChange={(e) => handleCBMChange(e, index)}
                            value={cbm[index]}
                            id="cbm"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="T/CBM"
                            readOnly
                            // onChange={(e) => handleTTOCBMChange(e, index)}
                            value={ttocbm[index]}
                            id="ttocbm"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="N.W"
                            onChange={(e) => handleNWChange(e, index)}
                            value={nw[index]}
                            id="nw"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="G.W"
                            readOnly
                            // onChange={(e) => handleGWChange(e, index)}
                            value={gw[index]}
                            id="gw"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "70px", minWidth: "70px", padding: "2px" }}
                          align="start"
                        >
                          <input
                            required
                            type="text"
                            style={{ width: "70px", minWidth: "70px" }}
                            className="form-control"
                            name="name"
                            placeholder="Status"
                            onChange={(e) => handleStatusChange(e, index)}
                            value={status[index]}
                            id="status"
                          />
                        </TableCell>
                        <TableCell sx={{ width: "70px", minWidth: "70px" }} align="start">
                          <input
                            required
                            type="text"
                            style={{ width: "150px", minWidth: "150px" }}
                            className="form-control"
                            name="name"
                            placeholder="Notes"
                            onChange={(e) => handleNotesChange(e, index)}
                            value={notes[index]}
                            id="notes"
                          />
                        </TableCell>
                      </Box>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </SuiBox>
            {/* </div> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditOrder;
