/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

// @mui material components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import "react-dropdown/style.css";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@material-ui/core";
import ReactLoading from "react-loading";

import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import useOrderAdding from "hooks/orders/useOrderAdding";
// import { useTranslation } from "react-i18next";
import { CCol, CForm, CFormInput, CFormSelect, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
// import DeleteLab from "./delete_lab";

function AddOrder() {
  // const  rows: [1];
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  const {
    status0,
    setStatus0,
    container,
    setContainer,
    rows,
    setRows,
    usernumber,
    setUserNumber,
    file,
    setFile,
    files1,
    setFiles1,
    dialogOpen,
    setDialogOpen,
    nw,
    setNW,
    status,
    setStatus,
    notes,
    setNotes,
    customers,
    itemarray,
    setItemArray,
    itemnumber,
    setItemNumber,
    photo,
    setPhoto,
    description1,
    setDescription1,
    description2,
    setDescription2,
    category,
    setCategory,
    ctn,
    setCTN,
    qtytoctn,
    setQtyToCtn,
    utoprice,
    setUtoPrice,
    cbm,
    setCBM,
    navigate,
    customer,
    setCustomer,
    statusarray,
    containers,
    shopnumber1,
    setShopNumber1,
    shopnumber2,
    setShopNumber2,
    phone,
    setPhone,
    deliverydate,
    setDeliveryDate,
    loading,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
    setDialogError,
    total_cbm,
    total_ctn,
    total_amount,
    total_gw,
    handleItemChange,
    handleItemNumberChange,
    handleDesc2Change,
    handlePhotoChange,
    handleCTNChange,
    handleQTYTOCTNChange,
    handleUTOPriceChange,
    handleCBMChange,
    handleNWChange,
    handleStatusChange,
    handleNotesChange,
    ttocbm,
    ttoqty,
    amount,
    gw,
    handleSubmit,
    validated,
    setOrderNumber,
  } = useOrderAdding();
  const { t } = useTranslation();
  return (
    <>
      {/* <DashboardNavbar /> */}
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card style={{ padding: "2rem" }}>
            <div
              onClick={() => setDialogOpen(true)}
              style={{
                color: "#17c1e8",
                cursor: "pointer",
                marginLeft: "20px",
                marginTop: "10px",
                width: "10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-arrow-left-square"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                />
              </svg>
            </div>
            <div style={{ width: "100%", margin: "20px" }}>
              <CForm
                className="row g-3 needs-validation"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <CRow>
                  <CCol md={3}>
                    <CFormSelect
                      id="productCategory"
                      label="customer"
                      feedbackInvalid="cusotmer required"
                      value={customer}
                      onChange={(e) => setCustomer(e.target.value)}
                      required
                    >
                      <option selected="" value="">
                        {" "}
                        Choose customer ...
                      </option>

                      {customers.map((user) => (
                        <option value={user.code}>{user.name}</option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol md={3}>
                    <CFormInput
                      type="text"
                      placeholder="shop number"
                      feedbackInvalid="shop number is required"
                      id="categoryshopnumberAr"
                      label="shop number"
                      required
                      onChange={(e) => {
                        setShopNumber2(e.target.value);
                      }}
                      value={shopnumber2}
                    />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput
                      type="text"
                      placeholder="order code"
                      feedbackInvalid="order code is required"
                      id="categoryordercodeAr"
                      label="order code"
                      required
                      onChange={(e) => setOrderNumber(e.target.value)}
                      value={customer + shopnumber2}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={3}>
                    <CFormInput
                      type="text"
                      placeholder="phone number"
                      feedbackInvalid="phone number is required"
                      id="categoryphonenumberAr"
                      label="phone number"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </CCol>
                  <CCol md={3}>
                    <CFormInput
                      type="date"
                      placeholder="delivery date"
                      feedbackInvalid="delivery date is required"
                      id="categorydeliverydateAr"
                      label="delivery date"
                      required
                      onChange={(e) => setDeliveryDate(e.target.value)}
                      value={deliverydate}
                    />
                  </CCol>
                  <CCol md={3}>
                    <CFormSelect
                      id="productCategory"
                      label="container"
                      feedbackInvalid="Container required"
                      value={container}
                      onChange={(e) => setContainer(e.target.value)}
                      required
                    >
                      <option selected="" value="">
                        {" "}
                        Choose container ...
                      </option>

                      {containers.map((user) => (
                        <option value={user.id}>{user.name}</option>
                      ))}
                    </CFormSelect>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total items"
                      feedbackInvalid="total items is required"
                      id="categorytotalitemsAr"
                      label="total items"
                      readOnly
                      value={itemarray.length}
                    />
                  </CCol>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total amount"
                      feedbackInvalid="total amount is required"
                      id="categorytotalamountAr"
                      label="total amount"
                      readOnly
                      value={total_amount}
                    />
                  </CCol>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total CTN"
                      feedbackInvalid="total CTN is required"
                      id="categorytotalCTNAr"
                      label="total CTN"
                      readOnly
                      value={Number(total_ctn)}
                    />
                  </CCol>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total CBM"
                      feedbackInvalid="total CBM is required"
                      id="categorytotalCBMAr"
                      label="total CBM"
                      readOnly
                      value={total_cbm}
                    />
                  </CCol>
                  <CCol md={2}>
                    <CFormInput
                      type="text"
                      placeholder="total GW"
                      feedbackInvalid="total GW is required"
                      id="categorytotalGWAr"
                      label="total GW"
                      readOnly
                      value={total_gw}
                    />
                  </CCol>
                </CRow>
                <SuiBox
                  style={{
                    backgroundColor: "#e9ecef",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table sx={{ overflow: "scroll", display: "block" }} aria-label="simple table">
                    <TableHead>
                      <TableRow style={{ display: "flex" }}>
                        <Box>
                          <TableCell sx={{ width: "100px", minWidth: "100px" }} align="center">
                            S.Code{" "}
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "120px", minWidth: "120px" }} align="center">
                            Item NO.
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "150px", minWidth: "150px" }} align="center">
                            Photo
                          </TableCell>
                        </Box>

                        <Box>
                          <TableCell sx={{ width: "180px", minWidth: "180px" }} align="center">
                            English
                          </TableCell>
                        </Box>

                        <Box>
                          <TableCell sx={{ width: "150px", minWidth: "150px" }} align="center">
                            CTN
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "70px", minWidth: "70px" }} align="center">
                            QTY/CTN
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "70px", minWidth: "70px" }} align="center">
                            T/QTY
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "110px", minWidth: "110px" }} align="center">
                            U/Price
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "120px", minWidth: "120px" }} align="center">
                            Amount
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "60px", minWidth: "60px" }} align="center">
                            CBM
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "110px", minWidth: "110px" }} align="center">
                            T/CBM
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "70px", minWidth: "70px" }} align="center">
                            N.W
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "130px", minWidth: "130px" }} align="center">
                            G.W
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "180px", minWidth: "180px" }} align="start">
                            Status
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                            Notes
                          </TableCell>
                        </Box>
                        <Box>
                          <TableCell sx={{ width: "300px", minWidth: "300px" }} align="center">
                            Operations
                          </TableCell>
                        </Box>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ display: "block" }}>
                      {rows.map((vla, index) => (
                        <TableRow key={`${index}key`} sx={{ minWidth: "1000px" }}>
                          <Box style={{ overflowX: "auto" }}>
                            <TableCell sx={{ width: "100px", minWidth: "100px" }} align="center">
                              <input
                                id="item"
                                required
                                onChange={(e) => handleItemChange(e, index)}
                                value={customer + shopnumber2 + -+(index + 1)}
                                type="text"
                                style={{ width: "100px", minWidth: "100px" }}
                                className="form-control"
                                name="name"
                                placeholder="Our Item"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "100px", minWidth: "100px" }} align="center">
                              <input
                                id="item"
                                required
                                onChange={(e) => handleItemNumberChange(e, index)}
                                value={itemnumber[index]}
                                type="text"
                                style={{ width: "100px", minWidth: "100px" }}
                                className="form-control"
                                name="name"
                                placeholder="Item Number"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "100px", minWidth: "100px" }} align="start">
                              <div style={{ display: "flex" }}>
                                <input
                                  required
                                  id="photo"
                                  type="file"
                                  style={{ width: "50px", minWidth: "50px" }}
                                  className="form-control"
                                  name="name"
                                  // placeholder="Photo"
                                  onChange={(e) => handlePhotoChange(e, index)}
                                  // value={photo[index]}
                                />
                                <img
                                  src={photo[index] ? URL.createObjectURL(photo[index]) : ""}
                                  width={70}
                                />
                              </div>
                            </TableCell>
                            {/* <TableCell>
                        <img
                         <img
                              
                              width={100}
                              style={{ position: "absolute", top: 0, left: 0, height: "120px" }}
                            />
                          src={files[index]}
                          alt="img"
                          style={{ width: "100px", marginTop: "10px" }}
                        />
                      </TableCell> */}
                            {/* <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                        <img
                          src={photo[index]}
                          style={{ width: "100px", height: "100px" }}
                          alt=""
                        />
                      </TableCell> */}

                            <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                              <input
                                required
                                id="desc2"
                                type="text"
                                style={{ width: "150px", minWidth: "150px" }}
                                className="form-control"
                                name="name"
                                placeholder="English"
                                onChange={(e) => handleDesc2Change(e, index)}
                                value={description2[index]}
                              />
                            </TableCell>

                            <TableCell sx={{ width: "60px", minWidth: "60px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "60px", minWidth: "60px" }}
                                className="form-control"
                                name="name"
                                placeholder="CTN"
                                onChange={(e) => handleCTNChange(e, index)}
                                value={ctn[index]}
                                id="ctn"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "70px", minWidth: "70px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="QTY/CTN"
                                onChange={(e) => handleQTYTOCTNChange(e, index)}
                                value={qtytoctn[index]}
                                id="qtytoctn"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "70px", minWidth: "70px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="T/QTY"
                                // onChange={(e) => handleTTOQTYChange(e, index)}
                                value={ttoqty[index]}
                                id="ttoqty"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "70px", minWidth: "70px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="U/Price"
                                onChange={(e) => handleUTOPriceChange(e, index)}
                                value={utoprice[index]}
                                id="utoprice"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "80px", minWidth: "80px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "80px", minWidth: "80px" }}
                                className="form-control"
                                name="name"
                                placeholder="Amount"
                                readOnly
                                // onChange={(e) => handleAmountChange(e, index)}
                                value={amount[index]}
                                // id="amount"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "60px", minWidth: "60px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "60px", minWidth: "60px" }}
                                className="form-control"
                                name="name"
                                placeholder="CBM"
                                onChange={(e) => handleCBMChange(e, index)}
                                value={cbm[index]}
                                id="cbm"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "60px", minWidth: "60px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "60px", minWidth: "60px" }}
                                className="form-control"
                                name="name"
                                placeholder="T/CBM"
                                readOnly
                                // onChange={(e) => handleTTOCBMChange(e, index)}
                                value={ttocbm[index]}
                                id="ttocbm"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "70px", minWidth: "70px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="N.W"
                                onChange={(e) => handleNWChange(e, index)}
                                value={nw[index]}
                                id="nw"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "70px", minWidth: "70px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "70px", minWidth: "70px" }}
                                className="form-control"
                                name="name"
                                placeholder="G.W"
                                readOnly
                                // onChange={(e) => handleGWChange(e, index)}
                                value={gw[index]}
                                id="gw"
                              />
                            </TableCell>
                            <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                              <select
                                id="customer_id"
                                onChange={(e) => handleStatusChange(e, index)}
                                className="form-control"
                                style={{ height: "40px" }}
                                value={status[index]}
                              >
                                <option>Select status</option>
                                {statusarray.map((user) => (
                                  <option value={user.name}>{user.name}</option>
                                ))}
                              </select>
                            </TableCell>
                            <TableCell sx={{ width: "150px", minWidth: "150px" }} align="start">
                              <input
                                required
                                type="text"
                                style={{ width: "150px", minWidth: "150px" }}
                                className="form-control"
                                name="name"
                                placeholder="Notes"
                                onChange={(e) => handleNotesChange(e, index)}
                                value={notes[index]}
                                id="notes"
                              />
                            </TableCell>
                            <TableCell
                              sx={{ width: "400px", minWidth: "400px" }}
                              style={{ flexDirection: "row" }}
                              align="space-around"
                            >
                              <Button
                                variant="contained"
                                style={{
                                  color: "white",
                                  width: "120px",
                                  fontSize: "10px",
                                  marginLeft: "10px",
                                }}
                                disabled={rows.length === 1}
                                onClick={() => {
                                  setRows([...rows.slice(0, index), ...rows.slice(index + 1)]);
                                  setItemArray((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setItemNumber((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setPhoto((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setDescription1((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setDescription1((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setDescription2((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setCategory((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setCTN((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setQtyToCtn((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  // setTtoQty((prevSate) => [
                                  //   ...prevSate.slice(0, index),
                                  //   ...prevSate.slice(index + 1),
                                  // ]);
                                  setUtoPrice((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  // setAmount((prevSate) => [
                                  //   ...prevSate.slice(0, index),
                                  //   ...prevSate.slice(index + 1),
                                  // ]);
                                  setCBM((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  // setTtoCBM((prevSate) => [
                                  //   ...prevSate.slice(0, index),
                                  //   ...prevSate.slice(index + 1),
                                  // ]);
                                  setNW((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  // setGW((prevSate) => [
                                  //   ...prevSate.slice(0, index),
                                  //   ...prevSate.slice(index + 1),
                                  // ]);
                                  setStatus((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                  setNotes((prevSate) => [
                                    ...prevSate.slice(0, index),
                                    ...prevSate.slice(index + 1),
                                  ]);
                                }}
                              >
                                Remove Item
                              </Button>
                            </TableCell>
                          </Box>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      width: "100px",
                      minWidth: "100px",
                      fontSize: "10px",
                      margin: "1rem",
                    }}
                    onClick={() => {
                      setItemArray((state) => [...state, ""]);
                      setItemNumber((state) => [...state, ""]);
                      setPhoto((state) => [...state, ""]);
                      setDescription1((state) => [...state, ""]);
                      setDescription2((state) => [...state, ""]);
                      setCategory((state) => [...state, ""]);
                      setCTN((state) => [...state, ""]);
                      setQtyToCtn((state) => [...state, ""]);
                      // setTtoQty((state) => [...state, ""]);
                      setUtoPrice((state) => [...state, ""]);
                      // setAmount((state) => [...state, ""]);
                      setCBM((state) => [...state, ""]);
                      // setTtoCBM((state) => [...state, ""]);
                      setNW((state) => [...state, ""]);
                      // setGW((state) => [...state, ""]);
                      setStatus((state) => [...state, ""]);
                      setNotes((state) => [...state, ""]);

                      setRows((current) => [...current, current.length]);
                    }}
                  >
                    Add Item
                  </Button>
                </SuiBox>
                {loading ? (
                  <ReactLoading color="blue" height={50} width={50} type="spokes" />
                ) : (
                  <Button
                    variant="contained"
                    style={{ margin: "20px", color: "white", width: "15%" }}
                    type="submit"
                  >
                    <span style={{ fontWeight: "bolder" }}>Add Order</span>
                  </Button>
                )}
              </CForm>
              <Dialog
                open={dialogSuccess}
                onClose={() => {
                  setDialogSuccess(false);
                  navigate("/orders");
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Order Added Successfully</DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      navigate("/orders");
                    }}
                    autoFocus
                  >
                    {t("ok")}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={dialogError}
                onClose={() => {
                  setDialogError(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Adding order failed</DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setDialogError(false);
                    }}
                    autoFocus
                  >
                    {t("ok")}
                  </Button>
                </DialogActions>
              </Dialog>
              {/* <div className="row">
                <div className="col-md-2" style={{ marginTop: "20px" }}>
                  <div className="form-group">
                    <AlertDialog
                      ItemNumber1={itemnumber}
                      ItemArray={itemarray}
                      photo={photo}
                      desc1={description1}
                      desc2={description2}
                      category={category}
                      ctn={ctn}
                      qtytoctn={qtytoctn}
                      totoqty={ttoqty}
                      utoprice={utoprice}
                      amount={amount}
                      cbm={cbm}
                      ttocbm={ttocbm}
                      nw={nw}
                      gw={gw}
                      status={status}
                      notes={notes}
                      shop_number1={shopnumber1}
                      shop_number2={shopnumber2}
                      customer_id={customer}
                      container_id={container}
                      phone_number={phone}
                      // order_number={`${shopnumber1}-${customer}`}
                      order_number={customer + shopnumber2}
                      delivery_date={deliverydate}
                      total_amount1={total_amount.toString()}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total Items
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total Items"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={itemarray.length}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total Amount
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total Amount"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_amount}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total CTN
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total CTN"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={Number(total_ctn)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total CBM
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total CBM"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_cbm}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label
                      className="control-label"
                      htmlFor="name"
                      style={{ fontSize: "15px", marginTop: "20px" }}
                    >
                      Total GW
                    </label>
                    <input
                      required
                      id="shop_no2"
                      type="text"
                      className="form-control"
                      name="name"
                      readOnly
                      placeholder="Total GW"
                      // onChange={(e) => setShopNumber2(e.target.value)}
                      value={total_gw}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </Card>
        </SuiBox>
      </SuiBox>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to go back? </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
Let Google help apps determine location. This means sending anonymous location data to
Google, even when no apps are running.
</DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>ok</Button> */}
          <Button
            onClick={() => {
              navigate("/containers");
            }}
            autoFocus
          >
            YES
          </Button>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </>
  );
}

export default AddOrder;
