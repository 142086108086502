/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */

import useContainers from "hooks/containers/useContainers";
import useCustomers from "hooks/customers/useCustomers";
import useFormValidation from "hooks/general/useFormValidation";
import useStatus from "hooks/status/useStatus";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useOrderAdding = () => {
  const [status0, setStatus0] = useState("");
  const [container, setContainer] = useState("");
  const [rows, setRows] = useState([0]);
  const [usernumber, setUserNumber] = useState();
  const [file, setFile] = useState();
  const [files1, setFiles1] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [nw, setNW] = useState([""]);
  // const [gw, setGW] = useState([""]);
  const [status, setStatus] = useState([""]);
  const [notes, setNotes] = useState([""]);
  const { customers } = useCustomers();
  const [itemarray, setItemArray] = useState([""]);
  const [itemnumber, setItemNumber] = useState([""]);
  const [photo, setPhoto] = useState([""]);
  const [description1, setDescription1] = useState([""]);
  const [description2, setDescription2] = useState([""]);
  const [category, setCategory] = useState([""]);
  const [ctn, setCTN] = useState([""]);
  const [qtytoctn, setQtyToCtn] = useState([""]);
  const [utoprice, setUtoPrice] = useState([""]);
  const [cbm, setCBM] = useState([""]);
  const navigate = useNavigate();
  const [customer, setCustomer] = useState("");
  const { status: statusarray } = useStatus();
  const { containers } = useContainers();
  const [shopnumber1, setShopNumber1] = useState("");
  const [shopnumber2, setShopNumber2] = useState("");
  const [phone, setPhone] = useState("");
  const [deliverydate, setDeliveryDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [order_number, setOrderNumber] = useState("");

  // contants of my array
  // const [amount, setAmount] = useState([""]);
  // eslint-disable-next-line arrow-body-style

  const ttocbm = cbm.map((e, index) => {
    return e * ctn[index];
  });

  const total_cbm = ttocbm.reduce((e1, e2) => {
    return e1 + e2;
  });
  // const calculatedTotalCtn = () => {
  //   let total_ctn = 0;
  //   total_ctn += Number(ctn) || 0;
  //   return total_ctn;
  // };
  const tot_ctn = ctn.map((e, index) => {
    return 1 * ctn[index];
  });
  const total_ctn = tot_ctn.reduce((e1, e2) => {
    return e1 + e2;
  });

  const ttoqty = ctn.map((e, index) => {
    return e * qtytoctn[index];
  });
  const amount = utoprice.map((e, index) => {
    return e * ttoqty[index];
  });

  const total_amount = amount.reduce((e1, e2) => {
    return e1 + e2;
  });

  // const [ttocbm, setTtoCBM] = useState([""]);
  const gw = nw.map((e, index) => {
    return e * ctn[index];
  });
  const total_gw = gw.reduce((e1, e2) => {
    return e1 + e2;
  });

  const handleItemChange = (event, index) => {
    setItemArray((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleItemNumberChange = (event, index) => {
    setItemNumber((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handlePhotoChange = (event, index) => {
    setPhoto((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.files[0] : el;
      })
    );
  };
  const handleDesc2Change = (event, index) => {
    setDescription2((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleCTNChange = (event, index) => {
    setCTN((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleQTYTOCTNChange = (event, index) => {
    setQtyToCtn((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleTTOQTYChange = (event, index) => {
  //   setTtoQty((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };

  const handleUTOPriceChange = (event, index) => {
    setUtoPrice((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleAmountChange = (event, index) => {
  //   setAmount((state) =>
  //     state.map((el, index1) => {
  //       console.log("enver", event.target.value);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleCBMChange = (event, index) => {
    setCBM((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleTTOCBMChange = (event, index) => {
  //   setTtoCBM((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleNWChange = (event, index) => {
    setNW((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  // const handleGWChange = (event, index) => {
  //   setGW((state) =>
  //     state.map((el, index1) => {
  //       console.log("index1", index1);
  //       return index === index1 ? event.target.value : el;
  //     })
  //   );
  // };
  const handleStatusChange = (event, index) => {
    setStatus((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };
  const handleNotesChange = (event, index) => {
    setNotes((state) =>
      state.map((el, index1) => {
        console.log("index1", index1);
        return index === index1 ? event.target.value : el;
      })
    );
  };

  function addOrder() {
    const formdata = new FormData();
    setOrderNumber(customer + shopnumber2);
    formdata.append("delivery_date", deliverydate);
    formdata.append("phone_number", phone);
    formdata.append("order_code", order_number);
    formdata.append("customer_id", customer);
    formdata.append("container_id", container);
    itemarray.map((item, index) => formdata.append(`our_item[${index}]`, itemarray[index]));
    itemnumber.map((item, index) => formdata.append(`item_number[${index}]`, itemnumber[index]));
    photo.map((item, index) => formdata.append(`photo[${index}]`, photo[index]));
    description1.map((item, index) =>
      formdata.append(`description1[${index}]`, description1[index])
    );
    description2.map((item, index) =>
      formdata.append(`description2[${index}]`, description2[index])
    );
    category.map((item, index) => formdata.append(`category[${index}]`, category[index]));
    ctn.map((item, index) => formdata.append(`ctn[${index}]`, ctn[index]));
    amount.map((item, index) => formdata.append(`amount[${index}]`, amount[index]));
    cbm.map((item, index) => formdata.append(`cbm[${index}]`, cbm[index]));
    status.map((item, index) => formdata.append(`status[${index}]`, status[index]));
    notes.map((item, index) => formdata.append(`notes[${index}]`, notes[index]));
    qtytoctn.map((item, index) => formdata.append(`qty_ctn[${index}]`, qtytoctn[index]));
    ttoqty.map((item, index) => formdata.append(`t_qty[${index}]`, ttoqty[index]));
    utoprice.map((item, index) => formdata.append(`u_price[${index}]`, utoprice[index]));
    ttocbm.map((item, index) => formdata.append(`t_cbm[${index}]`, ttocbm[index]));
    nw.map((item, index) => formdata.append(`n_w[${index}]`, nw[index]));
    gw.map((item, index) => formdata.append(`g_w[${index}]`, gw[index]));

    const headers = { ContentType: `application/json` };
    const url = "https://trust-cargo.co/anas_backend/api/add_order";

    axios
      .post(url, formdata, headers)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDialogSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setDialogError(true);
      });
  }

  const { handleSubmit, validated } = useFormValidation(addOrder);

  return {
    handleSubmit,
    validated,
    status0,
    setStatus0,
    container,
    setContainer,
    rows,
    setRows,
    usernumber,
    setUserNumber,
    file,
    setFile,
    files1,
    setFiles1,
    dialogOpen,
    setDialogOpen,
    nw,
    setNW,
    status,
    setStatus,
    notes,
    setNotes,
    customers,
    itemarray,
    setItemArray,
    itemnumber,
    setItemNumber,
    photo,
    setPhoto,
    description1,
    setDescription1,
    description2,
    setDescription2,
    category,
    setCategory,
    ctn,
    setCTN,
    qtytoctn,
    setQtyToCtn,
    utoprice,
    setUtoPrice,
    cbm,
    setCBM,
    navigate,
    customer,
    setCustomer,
    statusarray,
    containers,
    shopnumber1,
    setShopNumber1,
    shopnumber2,
    setShopNumber2,
    phone,
    setPhone,
    deliverydate,
    setDeliveryDate,
    loading,
    dialogSuccess,
    setDialogSuccess,
    dialogError,
    setDialogError,
    total_cbm,
    total_ctn,
    total_amount,
    total_gw,
    handleItemChange,
    handleItemNumberChange,
    handleDesc2Change,
    handlePhotoChange,
    handleCTNChange,
    handleQTYTOCTNChange,
    handleUTOPriceChange,
    handleCBMChange,
    handleNWChange,
    handleStatusChange,
    handleNotesChange,
    ttocbm,
    ttoqty,
    amount,
    gw,
    setOrderNumber,
  };
};

export default useOrderAdding;
