/* eslint-disable dot-notation */
/* eslint-disable no-template-curly-in-string */
/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {
  Box,
  // TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  // Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useTranslation } from "react-i18next";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteReservation from "./delete_reservation";

function Transactions() {
  const [users, setUsers] = useState([]);
  const { t } = useTranslation();
  const fetchData = async () => {
    const headers = { ContentType: `application/json` };
    const response = await fetch("https://trust-cargo.co/anas_backend/api/transactions", {
      headers,
    });
    const data = await response.json();
    // eslint-disable-next-line dot-notation
    setUsers(data["transactions"]);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Transactions</SuiTypography>
            </SuiBox>
            <div style={{ width: "200px", margin: "20px", color: "green" }}>
              <Button
                variant="contained"
                style={{ color: "white" }}
                onClick={() => {
                  navigate("/add_transaction");
                }}
              >
                Add Transaction
              </Button>
            </div>

            <Table sx={{ minWidth: 1000 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ display: "flex" }}>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      Order No.
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      User.Number
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      Acc.Name
                    </TableCell>
                  </Box>

                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      Debet
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      Credit
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      Currency
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      Val-Curr
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      E-Debit
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      E-Credit
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      Balance
                    </TableCell>
                  </Box>
                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      Notes
                    </TableCell>
                  </Box>

                  <Box>
                    <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                      {t("operations")}
                    </TableCell>
                  </Box>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user.name}
                    sx={{
                      // "&:last-child td, &:last-child th": { border: 0 },
                      display: "flex",
                    }}
                  >
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.name ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.name_account ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.account_name ?? "-"}
                      </TableCell>
                    </Box>

                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.debet ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell align="center" sx={{ width: window.innerWidth / 12 }}>
                        {user.credit ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.currency ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.val_currency ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.e_debet ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.e_credit ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.balance ?? "-"}
                      </TableCell>
                    </Box>
                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        {user.notes ?? "-"}
                      </TableCell>
                    </Box>

                    <Box>
                      <TableCell sx={{ width: window.innerWidth / 12 }} align="center">
                        <SuiTypography
                          component="a"
                          href="#"
                          variant="caption"
                          color="secondary"
                          fontWeight="medium"
                          onClick={() => {
                            navigate(`/edit_reservation/${user.id}`);
                          }}
                        >
                          {t("edit")}
                        </SuiTypography>
                        <DeleteReservation fetch={fetchData} id={user.id} />
                      </TableCell>
                    </Box>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* </TableContainer> */}
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Transactions;
